import React from 'react'
import './style.scss'

const Footer = () => (
  <section className="margin-top-8">
    <div className="columns is-centered footer-section">
      <div className="column is-10">
        <h3 className="footer-title">Let's Connect</h3>
        <p className="desc">Feel free to reach out for collaborations or just a friendly hello.</p>
        <div className="contact-text">
          <p>willy.syafiq@gmail.com</p>
          <p>(+62)896 3635 4693</p>
        </div>
        <div className="social">
          <ul>
            <li><a href="https://www.instagram.com/willy.syafiq/" target="_blank" rel="noreferrer">Instagram</a></li>
            <span className="link-divider">/</span>
            <li><a href="https://www.linkedin.com/in/willy-ahmad-syafiq-35095610a/" target="_blank" rel="noreferrer">LinkedIn</a></li>
            <span className="link-divider">/</span>
            <li><a href="https://twitter.com/willy_as" target="_blank" rel="noreferrer">Twitter</a></li>
            <span className="link-divider">/</span>
            <li><a href="https://dribbble.com/willysyafiq" target="_blank" rel="noreferrer">Dribbble</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default Footer
