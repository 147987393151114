import React from 'react';
import Layout from '../../components/layout';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ContentCopy from '../../components/content-copy';
// import ContentImageXL from '../../components/content-image-xl';
// import ContentImageL from '../../components/content-image-l';
import ContentImageM from '../../components/content-image-m';


const VisionMobileUT = ({ data }) => (
    <Layout>
        <section className="columns is-centered case-studies is-gapless is-multiline">
            <div className="column is-12">
                <Img
                    className="featured-image"
                    fluid={data.visionPlusUTHero.childImageSharp.fluid}
                    alt="Featured Image"
                />
            </div>
            <div className="column is-12 padding-bottom-0 padding-top-0">
                <div className="columns is-centered">
                    <div className="column is-5 is-offset-1">
                        <div className="page-title-wrap margin-bot-3">
                            <span className="title-decoratiion"></span>
                            <h1 className="general-page-title">Vision+ Mobile App<br />Usability Testing During Pandemic</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-5 padding-top-0">
                <div className="table-wrapper">
                    <table className="table is-fullwidth">
                        <tbody>
                            <tr>
                                <td><h2>Client</h2></td>
                                <td><p>Vision+</p></td>
                            </tr>
                            <tr>
                                <td><h2>Overview</h2></td>
                                <td><p>Vision+ is an application service for watching TV and streaming movies from Smartphones up to Smart TV. Part of MNC Group.
                                <br /><br />
                                Long story short, I joined the company in the middle of a big redesign process and I found that so many usability issues in crucial features such as package payment but seems like everyone was ignoring it and I wasn't allowed to do any changes at that phase.
                                <br /><br />
                                Basically, previous designers only focused on refresh the look and feel only and ignore major issues that affect conversion rates which would make the product roadmap irrelevant.
                                </p></td>
                            </tr>
                            <tr>
                                <td><h2>Outcome</h2></td>
                                <td><p>Provide prioritized list of issues (backlog) that divided into critical, serious, and minor categories.</p><br />
                                    <p>Insights/ideas for the next iteration.</p>
                                </td>
                            </tr>
                            <tr>
                                <td><h2>My Role</h2></td>
                                <td><p>UI/UX Design</p></td>
                            </tr>
                            <tr>
                                <td><h2>Timeline</h2></td>
                                <td><p>It took roughly 2 weeks</p></td>
                            </tr>
                            <tr>
                                <td><h2>Tools</h2></td>
                                <td><p>Vision+ Staging App, Zoom, WhatsApp, Miro, Figma</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="columns is-centered padding-top-0 is-multiline">
                <div className="column is-12">
                    {/* <ContentImageM>
                        <Img
                            fluid={data.remoteUTSetup.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM> */}

                    <ContentCopy>
                        <h3>Objective</h3>
                        <p>To determine overall design inconsistencies and usability problem areas within the user interface and content areas so that the team can adjust the product roadmap accordingly.</p><br />

                        <p>Exercise the application under controlled test conditions with representative users. Data will be used to access whether usability goals regarding an effective, efficient, and well-received user interface have been achieved. </p><br />

                        <p>Establish baseline user performance and user-satisfaction levels of the user interface for future usability evaluations.</p><br />

                        <h3>Challenges</h3>
                        <ul>
                            <li>Scattered design files, even worse half of them were missing due to previous designers resign without any proper documentation.</li>
                            <li>No official support (conducted outside the working hours)</li>
                            <li>No research budget (no incentives)</li>
                            <li>Hard to conduct an in-person testing during the pandemic</li>
                            <li>Too many features that need to be validated at once</li>
                            <li>People tend to avoid video calls since they overwhelmed already by their work-from-home hustle.</li>
                        </ul>

                        <h3>Methodology</h3>
                        <p>Considering all those challenges I decided to go with the moderated remote usability testing using a staging app.</p><br />
                        <p>Please note that this design is from previous designers, as is.</p>
                    </ContentCopy>

                    <ContentImageM>
                        <Img
                            fluid={data.stagingAppScreen.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>

                    <ContentCopy>
                        <p>Recruited 5 participants. They use their laptop’s camera to show and record the interaction via Zoom app (focus on their hands and phone only).</p>
                    </ContentCopy>

                    <ContentImageM>
                        <Img
                            fluid={data.remoteUTSetup.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>

                    <ContentCopy>
                        <h3>Metrics</h3>
                        <ul>
                            <li>Completion rate</li>
                            <li>Time on task</li>
                            <li>Critical errors</li>
                            <li>Non-critical errors</li>
                        </ul>

                        <h3>Participants Criteria</h3>
                        <p>Currently, we don’t have user persona yet for our products so we use general criteria instead. </p>
                        <ul>
                            <li>Currently use paid/free Over-the-top media services</li>
                            <li>Indonesian citizens</li>
                            <li>13-60 years old</li>
                            <li>Use Android/iOS devices on daily basis </li>
                        </ul>
                        <p><b>NOTES:</b> V+ didn't give any kind of incentives for participants
and there is no non-disclosure agreement.</p><br />
                    </ContentCopy>

                    <ContentImageM>
                        <Img
                            fluid={data.participantsTable.childImageSharp.fluid}
                            alt="participants"
                        />
                    </ContentImageM>

                    <ContentCopy>
                        <h2>The Draft</h2>
                        <h3>Scenario and Tasks</h3>
                        <p>Since it's hard to find respondents without budget and official supports I need to get the most of each testing session. However, I still have to keep it short to avoid overwhelming respondents with tedious tasks that would affect the findings.
                        <br /> <br />
                        The ultimate goals of this study are to uncover as many as possible usability issues in the core features, get the buy-in to reevaluate the design, and establish baseline user performance and user-satisfaction levels of the user interface I tried to make scenarios that could touch those aspects in one session that took roughly 30-45min per respondent.
                        </p>
                        <h4>Senario #1</h4>
                        <p>You just installed the app and want to give it a try.</p>
                        <ol>
                            <li>Signup using your preference method</li>
                            <li>Try something you’d like to watch (Movies, Series, TV)</li>
                        </ol>

                        <h4>Scenario #2</h4>
                        <p>You finally found what are you looking for but unfortunately you can’t watch it now, you want to mark that movie to be watched later.</p>
                        <ol>
                            <li>Add a movie or series to “My List”</li>
                            <li>Find your added movies or series</li>
                        </ol>

                        <h4>Scenario #3</h4>
                        <p>You are looking for a movies called “Keluarga Cemara” by using the search feature.</p>
                        <ol>
                            <li>Try to find Keluarga Cemara series</li>
                        </ol>

                        <h4>Scenario #4</h4>
                        <p>You decided to to buy premium package for 1 month so that you can watch the premium content, now go and finish the payment process until you able to watch the content.</p>
                        <ol>
                            <li>Buy premium package, choose the 1 month option</li>
                            <li>Check your premium status</li>
                        </ol>

                        <h4>Sceanrio #5</h4>
                        <p>For some reason you need to logout from the app. Now you need to login to enter the app again.</p>
                        <ol>
                            <li>Logout from the app</li>
                            <li>Login using the previous account</li>
                        </ol>

                        <h3>Pre-test interview</h3>
                        <ol>
                            <li>What do you do for living? What’s your daily routine looks like? (Weekday & Weekend)</li>
                            <li>Do you currently use paid/free streaming platforms? (music & video) if any, what are they? how do you find them? do you pay for it?</li>
                            <li>What kind of content you love the most? (Movie, Series, TV) please give examples</li>
                            <li>Do you currently subscribe to a Paid TV?</li>
                            <li>How long you spend time watching content? (during your free time & working hours)</li>
                        </ol>
                        <h3>Debrief</h3>
                        <ol>
                            <li>How would you describe your overall experience with the prototype?</li>
                            <li>How would you rate the looks of this prototype? (1-5)</li>
                            <li>What did you like the most from the prototype? Why?</li>
                            <li>What did you like the least? Why? Did you encounter any difficulties as you used it? Which ones?</li>
                            <li>Do you have any suggestions regarding features?</li>
                            <li>What’s your consideration while choosing a streaming platform?</li>
                        </ol>
                    </ContentCopy>


                    <ContentCopy>
                        <h3>Results</h3>
                        <p>Divided findings per each case and general findings. Here are the snapshot of them.</p>
                    </ContentCopy>

                    <ContentImageM>
                        <Img
                            fluid={data.scenario1.childImageSharp.fluid}
                            alt="scenario"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.scenario2.childImageSharp.fluid}
                            alt="scenario"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.scenario3.childImageSharp.fluid}
                            alt="scenario"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.scenario4.childImageSharp.fluid}
                            alt="scenario"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.scenario5.childImageSharp.fluid}
                            alt="scenario"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.generalInsights.childImageSharp.fluid}
                            alt="scenario"
                        />
                    </ContentImageM>

                    <ContentCopy>
                        <h3>Findings</h3>
                        <p>Divided findings per each case and general findings and then merge similar issues.</p>
                    </ContentCopy>

                    <ContentImageM>
                        <Img
                            fluid={data.sticknoteFindings.childImageSharp.fluid}
                            alt="sticknotes"
                        />
                    </ContentImageM>

                    <ContentCopy>
                        <h3>Key Insights</h3>
                        <p>Here are the summarized version that categorized into critical, serious, and minor issues.</p>

                        <h4>Critical Issues</h4>
                        <ul>
                            <li>Only 40% of the participant able to complete the premium package purchasing task.</li>
                            <li>There are couple UI pattern violations that caused user get to the wrong page.</li>
                            <li>Connect icon placed at the top right screen caused so many frictions to users.</li>
                            <li>Basic search functionality such as autocomplete and search results is not working properly.</li>
                        </ul>

                        <h4>Serious Issues</h4>
                        <ul>
                            <li>Poor accesibility in couple of screen caused user wasn’t able to read labels in the first attempt.</li>
                            <li>Generally, the overall impression regarding the functionality and the design of the prototype (staging app) wasn't good.</li>
                            <li>Preference selection that occurs after user signup isn’t working as it should be & users didn’t really engage with the feature.</li>
                        </ul>

                        <h4>Minor Issues</h4>
                        <ul>
                            <li>Cluttered & buggy component occurred here and there (affects both aesthetic and functionality of the app).</li>
                            <li>Impression and sentiment about the collection of movies/series and movie posters are bad (keep in mind it may be a bit bias since it’s only a staging app).</li>
                        </ul>
                    </ContentCopy>


                    <ContentCopy>
                        <h3>Problem Severity Matrix</h3>
                        <p>In order to prioritize the issues/insights to stakeholders, I used a problem severity matrix to help classified visually.</p>
                        <h4>Impact</h4>
                        <p>Impact is the ranking of the consequences of the problem by defining the level of impact that the problem has on successful task completion. There are three levels of impact:</p>
                        <ul>
                            <li>High: prevents the user from completing the task (critical error)</li>
                            <li>Moderate: causes user difficulty but the task can be completed (non-critical error)</li>
                            <li>Low: minor problems that do not significantly affect the task completion (non-critical error)</li>
                        </ul>
                        <h4>Frequency</h4>
                        <ul>
                            <li>High: 60% or more of the participants experience the problem</li>
                            <li>Moderate: 21% - 59% of participants experience the problem</li>
                            <li>Low: 20% or fewer of the participants experience the problem</li>
                        </ul>
                        <p>Frequency is the percentage of participants who experience the problem when working on a task.</p>
                    </ContentCopy>

                    <ContentImageM>
                        <Img
                            fluid={data.problemMatrix.childImageSharp.fluid}
                            alt="problem severity matrix"
                        />
                    </ContentImageM>

                    <ContentCopy>
                        <h3>Project Learnings</h3>
                        <ul>
                            <li>Conduct a remote usability testing requires a practice session that should taken into the account, do a quick exercise to warm user up and get familiar with the situation. Always prepare a plan B when internet connection goes crazy e.g educate respondent about how to switch to an alternative and what to do if suddenly disconnected from the call.</li>
                            <li>When recording the session make sure the Zoom screen is locked to the respondent side so we won't miss any single moment in the recording. </li>
                            <li>Don't make it personal, all your extra miles effort to validate on your own without official supports like this could sometimes be ignored by stakeholders regardless of all the insights were really helpful or not. Sometimes they sacrifice one product to be left flawed to develop new platforms / new features so that they can keep their own KPIs as managers of other divisions. Even when its product has the highest number of potential users that would generate revenue compared to other platforms.</li>
                        </ul>
                    </ContentCopy>


                </div>
            </div>
            <div className="column is-12">
                <hr />
            </div>

            {/* More Cases Section  */}
            <div className="column is-10">
                <div className="columns is-centered is-multiline">
                    <div className="column is-12">
                        <h3 className="has-text-centered margin-top-0">More Case Studies</h3>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/volantis-design-system">
                                        <Img fluid={data.greduDS.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/volantis-design-system"><h2 className="read-more-title">Gredu Design System Ver 0.1</h2></Link>
                                        <Link to="/case-studies/volantis-design-system"><p className="read-more-excerp">A design system starter that will cover more than 5 products in the school ecosystem (web, iOS, & Android).</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/hris-markplus">
                                        <Img fluid={data.hrisMarkplus.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/hris-markplus"><h2 className="read-more-title">HRIS MarkPlus, Inc.</h2></Link>
                                        <Link to="/case-studies/hris-markplus"><p>Human resource information system that covers attendance, leave, benefits, employees information, etc.</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/student-app-course">
                                        <Img fluid={data.volantisDS.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/student-app-course"><h2 className="read-more-title">Volantis Design System</h2></Link>
                                        <Link to="/case-studies/student-app-course"><p>The design language that speaks for B2B startup with a modern approach. Covering foundation, UI Kit (for designer & engineer), design assets central.</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout >
);

export default VisionMobileUT;


export const query = graphql`
  query VisionMobileUTQuery {
    visionPlusUTHero: file(relativePath: { eq: "visionplus-mobile-ut-hero.png" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    participantsTable: file(relativePath: { eq: "participants-table.png" }) {
        childImageSharp {
            fluid(quality: 95){
                ...GatsbyImageSharpFluid
            }
        }
    }
    remoteUTSetup: file(relativePath: { eq: "remote-ut-setup.jpg" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    sticknoteFindings: file(relativePath: { eq: "visionplus-sticknotes-ut.png" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    problemMatrix: file(relativePath: { eq: "problem-severity-matrix.png" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    scenario1: file(relativePath: { eq: "scenario-1.png" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    scenario2: file(relativePath: { eq: "scenario-2.png" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    scenario3: file(relativePath: { eq: "scenario-3.png" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    scenario4: file(relativePath: { eq: "scenario-4.png" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    scenario5: file(relativePath: { eq: "scenario-5.png" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    generalInsights: file(relativePath: { eq: "general-insights.png" }) {
        childImageSharp {
            fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }

    greduDS:file(relativePath: { eq: "gredu-ds-page.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

    hrisMarkplus: file(relativePath: { eq: "hris-markplus.png" }) {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    volantisDS: file(relativePath: { eq: "volantis-ds-page.png" }) {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    stagingAppScreen: file(relativePath: { eq: "staging-app-visionplus.jpg" }) {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid
            }
        }
    }

  }
`
