import React from 'react'
import './style.scss'

const ContentImageM = ({ children }) => (
    <div className="content-image-m">
        {children}
    </div>
)

export default ContentImageM
