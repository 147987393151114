import React from 'react'
import './style.scss'

const ContentCopy = ({ children }) => (
    <div className="content-copy">
        {children}
    </div>
)

export default ContentCopy
